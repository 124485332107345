<template>
  <v-main class="pa-0">
    <v-container
      id="main-container"
      fluid
      class="pa-0"
    >
      <router-view
        v-if="userLoaded"
        :key="$route.path"
      />
    </v-container>
  </v-main>
</template>

<script>
  import { get } from 'vuex-pathify'
  export default {
    name: 'DefaultView',

    computed: { userLoaded: get('user/loaded') },
  }
</script>

<style lang="sass" >

</style>
